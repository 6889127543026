import React, { useEffect, useState } from 'react'
import MoreInfo from '../../../src/containers/MoreInfoForm'
import queryString from 'query-string-es5'
import { graphql } from 'gatsby'

function AddCustomCss(css) {
    const head = document.getElementsByTagName('head')[0]
    const styleEl = document.createElement('style')
    styleEl.innerHTML = css
    head.appendChild(styleEl)
}

const MoreInfoPage = () => {
    const [params, setParams] = useState({
        loaded: false, // used to make sure the component does not display until data is received from the parent
    })

    useEffect(() => {
        window.addEventListener(
            'message',
            (event) => {
                if (event.data.formInit) {
                    const data = event.data
                    AddCustomCss(data.css)
                    setParams({
                        loaded: true,
                        ...data,
                    })
                }
            },
            false
        )
        window.parent.postMessage({ eventCode: 'ready' }, '*')
    }, [])

    return params.loaded ? <MoreInfo isIframe={true} {...params} /> : null
}

export default MoreInfoPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
